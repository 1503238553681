import { useEffect, useState } from "react";
import useHandleTranslations from "./useHandleTranslations";

const defaultCategories = {
  featured: "featured",
  all: "all",
  energy: "Energy",
  telecom: "Telecom",
  water: "Water",
};

const defaultFilterButtons = [
  {
    name: "Featured",
    category: defaultCategories.featured,
  },
  {
    name: "All",
    category: defaultCategories.all,
    count: 0,
  },
  {
    name: "Energy",
    category: defaultCategories.energy,
    count: 0,
  },
  {
    name: "Telecom",
    category: defaultCategories.telecom,
    count: 0,
  },
  {
    name: "Water",
    category: defaultCategories.water,
    count: 0,
  },
];

const translationKeys = [
  "suppliers-tab-1",
  "suppliers-tab-2",
  "suppliers-tab-3",
  "suppliers-tab-4",
  "suppliers-tab-5",
];

const useHandleData = () => {
  const [categories, setCategories] = useState(defaultCategories);
  const [filterButtons, setFilterButtons] = useState(defaultFilterButtons);
  const { translatedText } = useHandleTranslations(translationKeys);

  const [initialSuppliersList, setInitialSuppliersList] = useState({});

  useEffect(() => {
    async function fetchData() {
      if (Object.keys(initialSuppliersList).length === 0) {
        //on first render add extra category "All"

        const { suppliersDTO } = await fetch("suppliers/all").then((resp) =>
          resp.json()
        );

        let allSuppliers = { ...suppliersDTO };
        allSuppliers[categories.all] = []; // initialieze "All" property with empty array

        // fill "All" property with all suppliers
        Object.entries(allSuppliers).forEach(([key, value]) => {
          // set isEnergySupplier property on each supplier
          value.forEach((item) => {
            item.isEnergySupplier = key === "Energy";
          });

          allSuppliers[categories.all] = [
            ...allSuppliers[categories.all],
            ...value,
          ];
        });

        allSuppliers[categories.featured] = [...allSuppliers[categories.all]];

        const suppliersLengthElem = document.querySelector(".suppliers-length");
        if (suppliersLengthElem) {
          suppliersLengthElem.textContent = allSuppliers.all.length;
        }

        setInitialSuppliersList(allSuppliers);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchTranslation() {
      if (Object.keys(initialSuppliersList).length > 0) {
        let filtersWithCount = [...filterButtons];

        //update every count property from filter buttons
        filterButtons.forEach((item, index) => {
          let currentCategory = initialSuppliersList[item.category];
          if (currentCategory) {
            filtersWithCount[index].count = currentCategory.length;
          }
        });

        if (translatedText) {
          Object.entries(translatedText).forEach(([key, value], index) => {
            filtersWithCount[index].name = value;
          });
        }

        setFilterButtons(filtersWithCount);
      }
    }
    fetchTranslation();
  }, [initialSuppliersList, translatedText]);

  return { categories, filterButtons, initialSuppliersList };
};

export default useHandleData;
