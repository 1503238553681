import { useEffect, useState } from "react";

const useHandleTranslations = (lokaliseKeys) => {
  const [translatedText, setTranslatedText] = useState();

  if(!lokaliseKeys || lokaliseKeys.length === 0) return;

  let keysToTranslate = [];

  if(!Array.isArray(lokaliseKeys)) {
    keysToTranslate.push(lokaliseKeys);
  } else {
    keysToTranslate = [...lokaliseKeys];
  }

  useEffect(() => {
    async function fetchTr() {
      const translatedData = await fetch("/translate", {
        method: "POST",
        body: JSON.stringify(keysToTranslate),
      })
        .then((resp) => resp.json())
        .then((data) => data);
      setTranslatedText(translatedData);
    }
    fetchTr();
  }, []);

  return { translatedText };
};

export default useHandleTranslations;