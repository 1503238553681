// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NoResults-module--omvvu {\n    display: grid;\n    justify-items: center;\n    gap: 17px;\n    grid-column: 1/-1;\n}\n\n.NoResults-module--DE4zc {\n    margin-top: 15px;\n    font-size: 21px;\n    line-height: 34px;\n    font-weight: 700;\n}\n\n.NoResults-module--stUId {\n    font-size: 21px;\n    line-height: 34px;\n    color: #98a5be;\n}", "",{"version":3,"sources":["webpack://./src/components/NoResults/NoResults.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qBAAqB;IACrB,SAAS;IACT,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB","sourcesContent":[".container {\n    display: grid;\n    justify-items: center;\n    gap: 17px;\n    grid-column: 1/-1;\n}\n\n.title {\n    margin-top: 15px;\n    font-size: 21px;\n    line-height: 34px;\n    font-weight: 700;\n}\n\n.subtitle {\n    font-size: 21px;\n    line-height: 34px;\n    color: #98a5be;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "NoResults-module--omvvu",
	"title": "NoResults-module--DE4zc",
	"subtitle": "NoResults-module--stUId"
};
export default ___CSS_LOADER_EXPORT___;
