// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Search-module--Rb3X6 {\n  margin-left: auto;\n  margin-right: auto;\n  width: 536px;\n  margin-bottom: 12px;\n}\n\n.Search-module--fOf2h {\n    width: 100%;\n    padding: 22px 24px;\n    border-radius: 9px;\n    border: none;\n    font-size: 20px;\n  }\n\n@media (max-width: 767px) {\n  .Search-module--Rb3X6 {\n    margin: 0 20px 20px 20px;\n    width: calc(100% - 40px);\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/Search/Search.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;EACZ,mBAAmB;AACrB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,kBAAkB;IAClB,YAAY;IACZ,eAAe;EACjB;;AAEF;EACE;IACE,wBAAwB;IACxB,wBAAwB;EAC1B;AACF","sourcesContent":[".suppliers-search-wrap {\n  margin-left: auto;\n  margin-right: auto;\n  width: 536px;\n  margin-bottom: 12px;\n}\n\n.quicksearch {\n    width: 100%;\n    padding: 22px 24px;\n    border-radius: 9px;\n    border: none;\n    font-size: 20px;\n  }\n\n@media (max-width: 767px) {\n  .suppliers-search-wrap {\n    margin: 0 20px 20px 20px;\n    width: calc(100% - 40px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"suppliers-search-wrap": "Search-module--Rb3X6",
	"quicksearch": "Search-module--fOf2h"
};
export default ___CSS_LOADER_EXPORT___;
