// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".App-module--UPalD {\n  display: grid;\n}\n\n.App-module--UPalD.App-module--Fgrz0 {\n  gap: 32px;\n}", "",{"version":3,"sources":["webpack://./src/App.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,SAAS;AACX","sourcesContent":[".app {\n  display: grid;\n}\n\n.app.energy {\n  gap: 32px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app": "App-module--UPalD",
	"energy": "App-module--Fgrz0"
};
export default ___CSS_LOADER_EXPORT___;
