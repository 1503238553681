import React, { useEffect, useState } from 'react'
import classes from './Search.module.css';
import useHandleTranslations from '../../hooks/useHandleTranslations';

const Search = ({ searchValue, searchHandler }) => {
    const [placeholderText, setPlaceholderText] = useState(
      "Search for a supplier"
    );
    const key = "suppliers-input-placeholder";
    const { translatedText } = useHandleTranslations(key);

    useEffect(() => {
        if(translatedText) {
            setPlaceholderText(translatedText[key]);
        }
    }, [translatedText]);

    return (
      <div className={classes["suppliers-search-wrap"]}>
        <input
          type="text"
          className={classes["quicksearch"]}
          placeholder={placeholderText}
          value={searchValue}
          onChange={searchHandler}
        />
      </div>
    );
}

export default Search
